import { enteringAmount, getFloatingNumber } from '@/Plugins/EnteringData'
import { INPUT_TYPES, INPUT_TYPES_REGX_MAP, INPUT_TYPES_REGX_PROPS } from '@/constants/common'
import Decimal from 'decimal.js'
import moment from 'moment-mini'

/**
 * Функция для возврата строки из экспоненциального числа
 * @param digits - число в экспоненциальном виде
 * @param amountScale - максимальное значение ограничения числа после запятой
 * @returns {*|string} - число в "человеческом" виде в виде строки
 */
const exportExponentialToString = (digits, amountScale) => {
  let exportString = ''
  let str = String(digits)
  // eslint-disable-next-line
  let order = str.replace(/.+e-/gm, '')
  // eslint-disable-next-line
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}

/**
 * Функция удаления последних нулей из отношениядвух чисел
 * @param surrendered - Отдаваемое число
 * @param requested - принимаемое число
 * @returns {string}
 */
export const courseCalculation = (surrendered, requested) => {
  let course = surrendered / requested
  course = course.toFixed(8)
  while (course.endsWith('0')) {
    course = course.substring(0, course.length - 1)
  }
  if (course.endsWith('.')) {
    course = course.substring(0, course.length - 1)
  }
  return course
}

/**
 * Функция перевода строки с экспоненциальной записью в обычныю
 * @param amount
 * @returns {string}
 */
export let convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    // eslint-disable-next-line
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
    // amount = amount.toFixed(numb);
  }
  return String(amount)
}

/**
 * Функция получения даты из timestamp
 * @param data - timestamp нужной даты
 * @returns {string} - возвращаемое значение в формате dd.mm.yyyy
 */
export const createDateOfTimeStamp = data => {
  return moment.unix(data).format('DD.MM.YYYY')
}

/**
 * Функция получения времени из timestamp
 * @param data - timestamp нужной даты
 * @returns {string} - возвращаемое значение в формате hh.mm
 */
export const createTimeOfTimeStamp = data => {
  return moment.unix(data).format('HH:mm')
}

export const createTimeWithSecondsOfTimeStamp = data => {
  return moment.unix(data).format('HH:mm:ss')
}

/**
 * Функция получения целого числа из числа с плавающей точкой
 * @param number - число для перевода
 * @param exponential - количество знаков после запятой
 * @returns {number} - целое число
 */
const toInteger = (number, exponential) => {
  number = Number(number)
  exponential = Number(exponential)
  let a = '1'
  for (let i = 0; i < exponential; i++) {
    a += '0'
  }
  return Math.round(number * Number(a))
}

/**
 * Функция для получения стоимости трансфера
 * @param a - сумма для перевода
 * @param b - комиссия
 * @returns {number} - возвращает стоимость операции
 */
export const Division = (a, b) => {
  let aE = 0
  if (a.toString().indexOf('e') !== -1) {
    aE = Number(a.toString().replace(/.+e-/gm, ''))
  } else {
    aE = a.toString().split('.')
    if (aE[1]) {
      aE = aE[1].length
    } else {
      aE = 0
    }
  }
  let bE = b.toString().split('.')
  bE = bE[1].length
  let aInt = toInteger(a, aE)
  let bInt = toInteger(b, bE)
  let itog = (aInt * bInt) / toInteger(1, 2 + aE + bE)
  return itog
}

export const controlledAmountInput = (event, targetProp, decimal = 5) => {
  const { target, inputType } = event
  const regString = `^\\d*[.]?\\d{0,${decimal}}$`
  const amountReg = new RegExp(regString, 'g')
  if (inputType === 'insertFromPaste' && !amountReg.test(target.value)) {
    target.value = targetProp
  } else {
    target.value = enteringAmount(target.value, decimal)
  }
  return target.value
}

export function controlledInput (attrsObj = {}) {
  const { event, targetProp, minStep, inputType = INPUT_TYPES.NUMBER, decimal = 5 } = attrsObj
  const { target } = event
  const regAttrs = INPUT_TYPES_REGX_PROPS[inputType].map(prop => attrsObj[prop])
  const regx = INPUT_TYPES_REGX_MAP[inputType](...regAttrs)
  if (!regx.test(target.value)) {
    target.value = targetProp
  } else {
    // TODO избавиться от страшного метода
    // target.value = enteringAmount(target.value, decimal)

    target.value = getFloatingNumber(target.value, decimal)
  }
  if (!target.value) return ''
  if (minStep) {
    const needDot = target.value?.at(-1) === '.'
    // target.value = target.value ? new Decimal(target.value).div(minStep).round().mul(minStep).toString() : ''
    // if (needDot) target.value = `${target.value}.`
    // const value = parseFloat(target.value)
    // console.log({ value })
    // if (isNaN(value)) return target.value
    if (target.value?.split('.')[1]?.length === decimal && new Decimal(target.value).lt(minStep)) {
      target.value = minStep
      return target.value
    }
    if (!new Decimal(target.value).modulo(minStep).eq(0)) {
      target.value = `${new Decimal(target.value).div(minStep).ceil().mul(minStep).toFixed()}${needDot ? '.' : ''}`
      return target.value
    }
  }
  return target.value
}

export const maskForAmount = (val, maximumFractionDigits = 2) => {
  const num = Number(val)
  return num?.toLocaleString('ru-RU', { minimumFractionDigits: maximumFractionDigits || 0, maximumFractionDigits: maximumFractionDigits || 0 })?.replace(',', '.')
}
